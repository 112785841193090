/* Main properties */

html {
  margin: 0;
  padding: 0;
  position: relative; // Footer stays bottom
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #faf9f8;
  margin-bottom: 220px; /* Default footer height */
  height:100%;
}

footer {
  padding-top:20px;
  padding-bottom:15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

main {
  position: relative;
  min-height: 100%;
}



/* Corbel font import (Fix Issue #59) */
@font-face {
  font-family: 'Corbel';
  src: url('fonts/Corbel/Corbel.woff2') format('woff2'),
      url('fonts/Corbel/Corbel.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CorbelBold';
  src: url('fonts/Corbel_Bold/Corbel-Bold.woff2') format('woff2'),
      url('fonts/Corbel_Bold/Corbel-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Consolas';
  src: url('fonts/Consolas/Consolas.woff2') format('woff2'),
      url('fonts/Consolas/Consolas.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



/* Fix bootstrap outline when click */
a:active,
a:focus,
button:active,
button:focus,
.ms-Button:active,
.ms-Button:focus {
  outline: 0;
  outline: none;
  box-shadow: none !important;
}



/* Responsive footer */
@media only screen and (max-width: 992px) {
  body {
    margin-bottom: 240px;
  }
}

@media only screen and (min-width: 769px) {
  footer .main-col .text {
    margin-right:5%;
  }
}

@media only screen and (max-width: 768px) {
  body {
    margin-bottom: 480px;
  }

  footer .row {
    text-align: center;
  }

  footer .contacts {
    margin-top:0px;
  }

  footer .contacts .text, footer .main-col .text {
    margin-left:25%;
    margin-right: 25%;
  }
}

@media only screen and (max-width: 485px) {
  body {
    margin-bottom: 500px;
  }
}

@media only screen and (max-width: 363px) {
  body {
    margin-bottom: 530px;
  }
}

@media only screen and (max-width: 270px) {
  body {
    margin-bottom: 600px;
  }
}

@media only screen and (max-width: 225px) {
  body {
    margin-bottom: 650px;
  }
}

@media only screen and (min-width: 769px) {
  footer .row {
    margin-left: 3%;
  }
}




/* Responsive menu and title */
@media only screen and (min-width: 696px) {
  .header-menu .pivot {
    display: block;
  }

  .header-menu .dropdown {
    display: none;
  }

  .header-menu .ms-FocusZone {  /* Header menu left margin on large displays */
    margin-left: 10px;
  }
}
@media only screen and (max-width: 695px) {
  .header-menu .pivot {
    display: none;
  }

  .header-menu .dropdown {
    display: block;
  }
}


@media only screen and (min-width: 592px) {
  .header-title .large-display {
    display:block;
  }

  .header-title .small-display {
    display:none;
  }
}

@media only screen and (max-width: 591px) {
  .header-title .large-display {
    display:none;
  }

  .header-title .small-display {
    display:block;
  }

  .header-title .small-display .logo-text {
    margin-left:-5%;
  }
}





/* Dropdown style for header-menu */
.header-menu .ms-Dropdown-title { 
  border-style: none;
  height: 44px;
  justify-content: center;
  text-align: center;
}


/* Grid system adjustments for filter container */
@media only screen and (max-width: 766px) {
  .courses-filter-options .col-sm-12 {
    max-width: 420px;
    text-align: center;
  }
}

@media only screen and (max-width: 540px) {
  .courses-filter-options .col-sm-12 {
    max-width: 280px;
    text-align: center;
  }
  
  /* Courses filter options è il container */
  .courses-filter-options, .department-choose .row {
    margin-right:0%;
    margin-left:0%;
  }
}



/* Section adjustments */
@media only screen and (max-width: 575px) {
  .department-choose .col-sm-6 {
    max-width: 280px;
    text-align: center;
  }

  .administrators .title {
    margin-right:15%;
    margin-left:15%;
  }

  .courses-filter-options .col-xs-12 {
    max-width: 250px;
    text-align: center;
  }

  .representatives .people-list .col-xs-12, .administrators .admin-list .col-xs-12 {
    margin-left:15%;
    max-width: 250px;
    text-align: center;
  }

  .representatives .people-list, .administrators .admin-list {
    justify-content: center;
  }

  .col-persona {
    max-width: 230px;
    margin-left:10%;
  }
}

@media only screen and (min-width: 481px) {
  .rules .row .col-xs-12 {
    max-width: 250px;
  }
}

@media only screen and (max-width: 480px) {
  .rules .row .col-xs-12 {
    min-width: 250px;
  }
}

@media only screen and (max-width: 380px) {
  .services .ms-Card {
    min-width:100px;
  }
}


/* Adjustment for mobile-view ms-card in coursesList and additionalGroupsView */
@media only screen and (min-width: 501px) {
  .course-list .listGridTile, .additional-groups .listGridTile {
    text-align: center;
    outline: none;
    position: relative; 
    float: left;
  }
}

@media only screen and (max-width: 500px) {
  .course-list .ms-Card, .additional-groups .ms-Card {
    width: 100%;
    max-width:100%;
  }

  .course-list .listGridTile, .additional-groups .listGridTile {
    width: 100% !important;
    max-width: 100%;
  }
}




/*---------------------
  Additional properties
---------------------*/

@media only screen and (max-width: 640px) { /* Dropdown items in mobile visualization when using icons act buggy. Must create issue in fluentUi repo */
  .ms-Dropdown-item {
    margin-bottom: 10px !important;
  }
}

.rules a {
  padding-left: 0 !important;
}

.ms-MessageBar-icon {
  display:none;
  position:absolute;
}

.header-menu .ms-Dropdown-title {
  line-height: 45px;
  padding:0;
}

.ms-List { /* Padding for card-list in courses and additionalGroups sections */
  padding: 3px;
}

.services .ms-CardSection {
  text-align: left;
}

.ms-TooltipHost { /* For showing tooltip correctly on settings icon */
  display: inline;
}